import en from './resources/en.json'
import ja from './resources/ja.json'
import zhHans from './resources/zh-Hans.json'
import zhHant from './resources/zh-Hant.json'

export const i18nResources = {
  en,
  ja,
  'zh-Hans': zhHans,
  'zh-Hant': zhHant,
}
